<template>
  <v-container
    class="blue-grey darken-4 align-content-start"
    fill-height
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        class="text-h3 white--text text-center"
      >
        全镇智慧医疗数据大屏
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        class="text-h3 white--text text-center"
      >
        <dv-border-box-10>
          <v-row>
            <v-col
              class="text-h5"
              cols="4"
            >
              纳入管理老年人总数
            </v-col>

            <v-col
              class="text-h5"
              cols="4"
            >
              服务家庭医生总数
            </v-col>

            <v-col
              class="text-h5"
              cols="4"
            >
              当天活跃设备数量
            </v-col>


            <v-col
              class="text-h2"
              cols="4"
            >
              <dv-digital-flop :config="patientTotalNum"/>
            </v-col>

            <v-col
              class="text-h2"
              cols="4"
            >
              <dv-digital-flop :config="doctorTotalNum" />
            </v-col>

            <v-col
              class="text-h2"
              cols="4"
            >
              <dv-digital-flop :config="deviceTotalNum" />
            </v-col>

          </v-row>

        </dv-border-box-10>

      </v-col>

    </v-row>

    <v-row>

      <v-col
        cols="3"
        class="text-h3 white--text text-center"
      >

        <v-responsive :aspect-ratio="1/1">
          <dv-border-box-10>
            <dv-charts v-if="currentSummaryLevel2" :option="patientGenderData" />
          </dv-border-box-10>
        </v-responsive>
      </v-col>

      <v-col
        cols="6"
      >
        <dv-border-box-10>
          <patient-line :trends-data="patientTrendsData"/>
        </dv-border-box-10>
      </v-col>

      <v-col
        cols="3"
        class="text-h3 white--text text-center"
      >

        <v-responsive :aspect-ratio="1/1">
          <dv-border-box-10>
            <dv-charts v-if="currentSummaryLevel2" :option="patientAgeLevelData" />
          </dv-border-box-10>
        </v-responsive>
      </v-col>

    </v-row>

    <v-row>

      <v-col
        cols="3"
        class="text-h3 white--text text-center"
        style="height:350px;"
      >
        <dv-border-box-11 title="血压比例">
          <dv-charts v-if="currentSummaryLevel2" :option="bloodPressureData" />
        </dv-border-box-11>
      </v-col>

      <v-col
        cols="3"
        class="text-h3 white--text text-center"
        style="height:350px;"
      >
        <dv-border-box-11 title="血氧比例">
          <dv-charts v-if="currentSummaryLevel2" :option="bloodOxygenData" />
        </dv-border-box-11>
      </v-col>

      <v-col
        cols="3"
        class="text-h3 white--text text-center"
        style="height:350px;"
      >
        <dv-border-box-11 title="运动量">
          <dv-charts v-if="currentSummaryLevel2" :option="sportData" />
        </dv-border-box-11>
      </v-col>

      <v-col
        cols="3"
        class="text-h3 white--text text-center"
        style="height:350px;"
      >
        <dv-border-box-11 title="睡眠质量">
          <dv-charts v-if="currentSummaryLevel2" :option="option" />
        </dv-border-box-11>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  import PatientLine from './components/PatientTrendsDataLine'

  export default {
    components: {
      PatientLine
    },
    name: 'app',
    data () {
      return {
        defaultDigitalFlopConfig: {
          number: [100],
          content: '{nt}',
          style: {
            fontSize: 100,
            fill: '#3de7c9'
          }
        },
        option: {
          series: [
              {
                  name: '睡眠质量',
                  type: 'pie',
                  radius: '55%',
                  center: ['50%', '60%'],
                  data: [
                      {value: this.random(300, 400), name: '睡眠质量高'},
                      {value: this.random(200, 300), name: '睡眠质量中'},
                      {value: this.random(100, 200), name: '睡眠质量低'}
                  ],
                  emphasis: {
                      itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                  }
              }
          ]
        },

        defaultGenderDataChartConfig: {
          tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
              orient: 'vertical',
              left: 10,
              data: ['男性患者', '女性患者']
          },
          series: [
              {
                  name: '访问来源',
                  type: 'pie',
                  radius: ['50%', '70%'],
                  avoidLabelOverlap: false,
                  label: {
                      show: false,
                      position: 'center'
                  },
                  emphasis: {
                      label: {
                          show: true,
                          fontSize: '30',
                          fontWeight: 'bold'
                      }
                  },
                  labelLine: {
                      show: false
                  },
                  data: []
              }
          ]
        },

        defaultAgeLevelDataChartConfig: {
          tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          series: [
              {
                name: '访问来源',
                type: 'pie',
                radius: '55%',
                center: ['50%', '50%'],
                data: [],
                roseType: 'radius',
                label: {
                    color: 'rgba(255, 255, 255, 0.3)'
                },
                labelLine: {
                    lineStyle: {
                        color: 'rgba(255, 255, 255, 0.3)'
                    },
                    smooth: 0.2,
                    length: 10,
                    length2: 20
                },
                itemStyle: {
                    color: '#c23531',
                    shadowBlur: 200,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                },

                animationType: 'scale',
                animationEasing: 'elasticOut',
                animationDelay: function () {
                    return Math.random() * 200;
                }
            }
          ]
        },

        defaultBloodPressureDataChartConfig: {
          color: ['#c23531', '#61a0a8', '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'],
          series: [
              {
                  name: '血压',
                  type: 'pie',
                  radius: '55%',
                  center: ['50%', '60%'],
                  data: [],
                  emphasis: {
                      itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                  }
              }
          ]
        },
        defaultBloodOxygenDataChartConfig: {
          color: ['#c23531', '#61a0a8', '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'],
          series: [
              {
                  name: '血氧',
                  type: 'pie',
                  radius: '55%',
                  center: ['50%', '60%'],
                  data: [],
                  emphasis: {
                      itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                  }
              }
          ]
        },

        defaultSportDataChartConfig: {
          color: ['#c23531', '#61a0a8', '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'],
          series: [
              {
                  name: '运动量',
                  type: 'pie',
                  radius: '55%',
                  center: ['50%', '60%'],
                  data: [],
                  emphasis: {
                      itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                  }
              }
          ]
        },
      }
    },

    computed: {

      ...mapGetters({
        currentRealtimeData: 'monitorScreen/currentRealtimeData',
        currentSummaryLevel2: 'monitorScreen/currentSummaryLevel2'
      }),

      patientTotalNum () {
        let res = JSON.parse(JSON.stringify(this.defaultDigitalFlopConfig))
        if (this.currentRealtimeData && this.currentRealtimeData.patientNum) {
          res.number = [this.currentRealtimeData.patientNum]
        }
        return res
      },

      doctorTotalNum () {
        let res = JSON.parse(JSON.stringify(this.defaultDigitalFlopConfig))
        if (this.currentRealtimeData && this.currentRealtimeData.doctorNum) {
          res.number = [this.currentRealtimeData.doctorNum]
        }
        return res
      },

      deviceTotalNum () {
        let res = JSON.parse(JSON.stringify(this.defaultDigitalFlopConfig))
        if (this.currentRealtimeData && this.currentRealtimeData.deviceNum) {
          res.number = [this.currentRealtimeData.deviceNum]
        }
        return res
      },

      patientGenderData() {
        let res = JSON.parse(JSON.stringify(this.defaultGenderDataChartConfig))
        if (this.currentSummaryLevel2 && this.currentSummaryLevel2.patientData) {
          res.series[0].data.push( { name: '男性患者', value: this.currentSummaryLevel2.patientData.maleNum} )
          res.series[0].data.push( { name: '女性患者', value: this.currentSummaryLevel2.patientData.femaleNum} )
        }
        return res
      },

      patientTrendsData() {
        if (this.currentSummaryLevel2 && this.currentSummaryLevel2.patientData && this.currentSummaryLevel2.patientData.trendsData) {
          return this.currentSummaryLevel2.patientData.trendsData
        }
        return []
      },

      patientAgeLevelData () {
        let res = JSON.parse(JSON.stringify(this.defaultAgeLevelDataChartConfig))
        if (this.currentSummaryLevel2 && this.currentSummaryLevel2.patientData) {
          res.series[0].data.push( { name: '90岁以上', value: this.currentSummaryLevel2.patientData.ageLevelOne} )
          res.series[0].data.push( { name: '75-90岁', value: this.currentSummaryLevel2.patientData.ageLevelTwo} )
          res.series[0].data.push( { name: '60-75岁', value: this.currentSummaryLevel2.patientData.ageLevelThree} )
          res.series[0].data.push( { name: '60以下', value: this.currentSummaryLevel2.patientData.ageLevelFour} )
        }
        return res
      },

      bloodPressureData() {
        let res = JSON.parse(JSON.stringify(this.defaultBloodPressureDataChartConfig))
        if (this.currentSummaryLevel2 && this.currentSummaryLevel2.bloodPressureData) {
          res.series[0].data.push( { name: '重度高血压', value: this.currentSummaryLevel2.bloodPressureData.higherNum} )
          res.series[0].data.push( { name: '中度高血压', value: this.currentSummaryLevel2.bloodPressureData.middleNum} )
          res.series[0].data.push( { name: '轻度高血压', value: this.currentSummaryLevel2.bloodPressureData.slightNum} )
          res.series[0].data.push( { name: '临界高血压', value: this.currentSummaryLevel2.bloodPressureData.boundaryNum} )
          res.series[0].data.push( { name: '正常', value: this.currentSummaryLevel2.bloodPressureData.normalNum} )

        }
        return res
      },

      bloodOxygenData() {
        let res = JSON.parse(JSON.stringify(this.defaultBloodOxygenDataChartConfig))
        if (this.currentSummaryLevel2 && this.currentSummaryLevel2.bloodOxygenData) {
          res.series[0].data.push( { name: '供氧不足', value: this.currentSummaryLevel2.bloodOxygenData.middleNum} )
          res.series[0].data.push( { name: '低血氧症', value: this.currentSummaryLevel2.bloodOxygenData.lowerNum} )
          res.series[0].data.push( { name: '正常', value: this.currentSummaryLevel2.bloodOxygenData.normalNum} )

        }
        return res
      },

      sportData () {
        let res = JSON.parse(JSON.stringify(this.defaultSportDataChartConfig))
        if (this.currentSummaryLevel2 && this.currentSummaryLevel2.sportData) {
          res.series[0].data.push( { name: '大于6000步', value: this.currentSummaryLevel2.sportData.stepLevelOne} )
          res.series[0].data.push( { name: '大于3000步', value: this.currentSummaryLevel2.sportData.stepLevelTwo} )
          res.series[0].data.push( { name: '大于1000步', value: this.currentSummaryLevel2.sportData.stepLevelThree} )
          res.series[0].data.push( { name: '小于1000步', value: this.currentSummaryLevel2.sportData.stepLevelFour} )

        }
        return res
      }
    },

    created () {
      this.queryMonitorRealtimeData()
      this.queryMonitorSummaryLevel2()
    },

    methods: {

      ...mapActions({
        queryMonitorRealtimeData: 'monitorScreen/queryMonitorRealtimeData',
        queryMonitorSummaryLevel2: 'monitorScreen/queryMonitorSummaryLevel2'
      }),

      random(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
      }

    }
  }
</script>
